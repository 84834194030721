import { Component, OnInit, OnDestroy } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SharedResources} from "../../services/shared_resources/shared-resources";

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.css']
})
export class DisplayComponent implements OnInit, OnDestroy {

  constructor(private http: HttpClient) { }
  comenzi_aprovizionare: any = [];
  comenzi_clienti: any = [];
  new_comenzi_aprovizionare: any = [];
  new_comenzi_clienti: any = [];
  isVertical: boolean = true;
  clienti: boolean = true;
  furnizori: boolean = true;
  intervalId: number = 0;

  ngOnInit(): void {

    if ( Date.now() - Number(localStorage.getItem('loginTime')) > 86400000) {
      SharedResources.currentScreen = 'login';
      localStorage.setItem('token', '');
      localStorage.setItem('isAuth', 'false');
      SharedResources._tokenBS.next('');
      SharedResources._isAuthBS.next(false);
      SharedResources._currentScreenBS.next('login');
    }


    this.http.get<any>(`${localStorage.getItem('APIAddress')}PRO_ComenziFurnizoriByStare(Stare='InLucru')`,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        })
      }).subscribe(
      (response: any) => {
        this.comenzi_aprovizionare = response.value.sort((a : any, b: any) => a.NrCrt - b.NrCrt);
      },
      (error: any) => {
        console.log(error);

      });


    this.http.get<any>(`${localStorage.getItem('APIAddress')}PRO_ComenziClientByStare(Stare='Livrabila')`,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        })
      }).subscribe(
      (response: any) => {
        //console.log(response);
        this.comenzi_clienti = response.value.sort((a : any, b: any) => a.NrCrt - b.NrCrt);
        //console.log(this.comenzi_clienti);
      },
      (error: any) => {
        console.log(error);

      });


    this.intervalId = setInterval( () => {
      if ( Date.now() - Number(localStorage.getItem('loginTime')) > 86400000) {
        SharedResources.currentScreen = 'login';
        localStorage.setItem('token', '');
        localStorage.setItem('isAuth', 'false');
        SharedResources._tokenBS.next('');
        SharedResources._isAuthBS.next(false);
        SharedResources._currentScreenBS.next('login');
      }


      this.http.get<any>(`${localStorage.getItem('APIAddress')}PRO_ComenziFurnizoriByStare(Stare='InLucru')`,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token')}`
          })
        }).subscribe(
        (response: any) => {
          //alert('Test');
          this.new_comenzi_aprovizionare = []
          for (let i = 0; i < response.value.length; i++) {
            let isNew = this.comenzi_aprovizionare.filter((ca: any) => ca.ComandaID == response.value[i].ComandaID).length == 0;
            if (isNew)
              this.new_comenzi_aprovizionare.push(response.value[i]);
            this.new_comenzi_aprovizionare = this.new_comenzi_aprovizionare.sort((a : any, b: any) => a.NrCrt - b.NrCrt);
          }
          this.comenzi_aprovizionare = response.value;

          this.http.get<any>(`${localStorage.getItem('APIAddress')}PRO_ComenziClientByStare(Stare='Livrabila')`,
            {
              headers: new HttpHeaders({
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`
              })
            }).subscribe(
            (response: any) => {
              //console.log(response);
              this.new_comenzi_clienti = []
              for (let i = 0; i < response.value.length; i++) {
                let isNew = this.comenzi_clienti.filter((cc: any) => cc.ComandaID == response.value[i].ComandaID).length == 0;
                if (isNew)
                  this.new_comenzi_clienti.push(response.value[i]);
                this.new_comenzi_clienti = this.new_comenzi_clienti.sort((a : any, b: any) => a.NrCrt - b.NrCrt);
              }
              this.comenzi_clienti = response.value;
              console.log(this.new_comenzi_clienti);
              console.log(this.new_comenzi_aprovizionare);
              if (this.isAnythingNew()) {
                let audio = new Audio();
                audio.src = "../../assets/ding_ding.mp3";
                audio.load();
                audio.play();
              }
            },
            (error: any) => {
              console.log(error);

            });

        },
        (error: any) => {
          console.log(error);

        });



    }, 10000);
  }
  ngOnDestroy() : void {
    clearInterval();
  }

  isNew(DataComanda: string) : boolean {
    return (Date.now() - Date.parse(DataComanda)) <= 1000 * 60 * 10;
  }

  isAnythingNew() : boolean {
    return this.new_comenzi_aprovizionare.length > 0 || this.new_comenzi_clienti.length > 0;
  }

  setVertical() {
    this.isVertical = true;
  }

  setHorizontal() {
    this.isVertical = false;
  }

  getNewComenziClienti() : any[]{
    return this.comenzi_clienti.filter((cc: any) => this.isNew(cc.ComandaDataCreare));
  }

  getNewComenziAprovizionare() : any[]{
    return this.comenzi_aprovizionare.filter((ca: any) => this.isNew(ca.ComandaDataCreare));
  }

  logout() {
    clearInterval(this.intervalId);
    localStorage.setItem('token', '');
    localStorage.setItem('isAuth', 'false');
    SharedResources._tokenBS.next(localStorage.getItem('token'));
    SharedResources._isAuthBS.next(false);
    SharedResources.currentScreen = 'login';
    SharedResources._currentScreenBS.next('login');
  }

  switchRaportVanzari() {
    clearInterval(this.intervalId);
    SharedResources.currentScreen = 'raport-vanzari';
    SharedResources._currentScreenBS.next('raport-vanzari');
  }

}
