<div class="container login_body">
  <div class="d-flex justify-content-center h-100">
    <div class="card">
      <div class="card-header">
        <h3>Sign In
          <!-- <input type="button" class="fas fa-globe float-right"/> -->
        </h3>
      </div>
      <div class="card-body">
        <form #loginForm="ngForm" (ngSubmit)="login(loginForm)">
          <div class="input-group form-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-user"></i></span>
            </div>
            <input type="text" name="username" ngModel class="form-control" placeholder="username">
          </div>
          <div class="input-group form-group" >
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-building"></i></span>
            </div>
            <select name="api" ngModel id="cars"class="form-control" >
              <option value="https://api.tekpad.era-erp.ro/">Tekpad</option>
              <option value="https://apimy.tekpad.era-erp.ro/">Mobino</option>
              <option value="https://demo.tekpad.era-erp.ro/">Demo</option>
              <option value="http://10.10.20.202:64870/">Local</option>
            </select>
          </div>
          <div class="input-group form-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-key"></i></span>
            </div>
            <input type="password" name="password" ngModel class="form-control" placeholder="password">
          </div>
<!--          <div class="row align-items-center remember">-->
<!--            <input type="checkbox">Remember Me-->
<!--          </div>-->
          <div class="form-group">
            <input type="submit" value="Login" class="btn float-right login_btn">
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
