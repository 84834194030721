<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand">Raport Vânzări</a>
  <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
          class="navbar-toggler"
          data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
    <span class="navbar-toggler-icon"></span>
  </button>


  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
    </ul>
    <dx-load-indicator
      id="large-indicator"
      height="60"
      width="60"
      [visible]="loadIndicatorVisible"
    ></dx-load-indicator>
    <button (click)="switchComenzi()" class="btn btn-info" style="margin: 5px">Dashboard Comenzi</button>
    <button (click)="logout()" class="btn btn-danger" style="margin: 5px">Logout</button>

  </div>
</nav>

<div class="container-fluid">
  <div class="container-fluid">
    <div class="row" style="margin-top: 10px">
      <div class="dx-field col-sm-3" style="margin-right: 10px; margin-left: 20px">
        <div class="row">
          <div class="dx-field-label">Data de start</div>
          <div class="dx-field-value">
            <dx-date-box [(value)]="startDate" displayFormat="yyyy-MM-dd"></dx-date-box>
          </div>
        </div>
      </div>
      <div class="dx-field col-sm-3" style="margin-left: 10px">
        <div class="row">

          <div class="dx-field-label">Data de sfârșit</div>
          <div class="dx-field-value">
            <dx-date-box [(value)]="endDate" displayFormat="yyyy-MM-dd"></dx-date-box>
          </div>
        </div>
      </div>
      <div class="dx-field col-sm-1" style="margin-left: 40px; display: flex; align-items: flex-end;">
        <dx-button
          style="width: 100%; padding-top: 0; margin-top: 0"
          stylingMode="contained"
          text="Aplică filtru"
          type="success"
          (onClick)="applyDateFilters()">
        </dx-button>
      </div>
    </div>
  </div>


  <dx-data-grid
    id="gridContainer"
    [dataSource]="dataSource"
    [remoteOperations]="false"
    [allowColumnReordering]="true"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [rowAlternationEnabled]="true"
    [showBorders]="true"
    [cacheEnabled]="true"
    style="margin: 5px"
  >
    <dxo-sorting
      mode="none"> <!-- or "multiple" | "none" -->
    </dxo-sorting>
    <dxo-pager
      [showPageSizeSelector]="true"
      [allowedPageSizes]="[10, 15, 20, 25, 50, 100]"
    ></dxo-pager>
    <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="configRaportVanzariV2Table"
    ></dxo-state-storing>
    <dxo-column-chooser
      [enabled]="false"> <!-- or "select" -->
    </dxo-column-chooser>
    <dxo-search-panel
      [visible]="true"
      [highlightCaseSensitive]="false"
    ></dxo-search-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

    <dxi-column dataField="Produs" caption="Produs" [groupIndex]="0"></dxi-column>
    <dxi-column dataField="CanalVanzare" caption="Canal Vanzare" [groupIndex]="1"></dxi-column>
    <dxi-column dataField="An" [groupIndex]="2"></dxi-column>
    <dxi-column dataField="Luna" [groupIndex]="3"></dxi-column>
    <dxi-column dataField="Ziua" [width]="300" [minWidth]="260" [fixed]="true"></dxi-column>
    <dxi-column dataField="StocRamas" [visible]="false" [showInColumnChooser]="false"></dxi-column>
    <dxi-column
      dataField="CantitateTotala"
      caption="Cantitate Vândută(după stornări)"
      dataType="number"
      format="#0 buc"
      alignment="right"
    ></dxi-column>
    <dxi-column
      dataField="CantitateVanduta"
      caption="Cantitate Vândută"
      dataType="number"
      format="#0 buc"
      alignment="right"
    ></dxi-column>
    <dxi-column
      dataField="CantitateStornata"
      caption="Cantitate Stornată"
      dataType="number"
      format="#0 buc"
      alignment="right"
    ></dxi-column>
    <dxi-column
      dataField="ValoareTotala"
      caption="Valoare Vândută(după stornări)"
      format="#0.00 RON"
      dataType="number"
      alignment="right"
    ></dxi-column>
    <dxi-column
      dataField="ValoareVanduta"
      caption="Valoare Vândută"
      format="#0.00 RON"
      dataType="number"
      alignment="right"
    ></dxi-column>
    <dxi-column
      dataField="ValoareStornata"
      caption="Valoare Stornată"
      format="#0.00 RON"
      dataType="number"
      alignment="right"
    ></dxi-column>
    <dxi-column
      dataField="MarjaTotala"
      caption="Marjă Totală"
      format="#0.00 RON"
      dataType="number"
      alignment="right"
    ></dxi-column>
    <dxi-column
      dataField="MarjaProcentuala"
      caption="Marjă Procentuală"
      format="percent"
      dataType="number"
      alignment="right"
    ></dxi-column>
    <dxi-column
      dataField="MarjaUnitara"
      caption="Marjă Unitară"
      format="#0.00 RON"
      dataType="number"
      alignment="right"
    ></dxi-column>
    <dxi-column
      dataField="ValoareUnitaraVanzare"
      caption="Valoare Unitară Vânzare"
      format="#0.00 RON"
      dataType="number"
      alignment="right"
    ></dxi-column>
    <dxi-column
      dataField="ValoareUnitaraAchizitionare"
      caption="Valoare Unitară Achiziție"
      format="#0.00 RON"
      dataType="number"
      alignment="right"
    ></dxi-column>


    <dxo-summary [calculateCustomSummary]="calculeazaAgregate">

      <dxi-group-item
        name="AgregatValoareTotala"
        summaryType="custom"
        valueFormat="#0.00 RON"
        column="ValoareTotala"
        [alignByColumn]="true">
      </dxi-group-item>
      <dxi-group-item
        name="AgregatValoareVanduta"
        summaryType="custom"
        valueFormat="#0.00 RON"
        column="ValoareVanduta"
        [alignByColumn]="true">
      </dxi-group-item>
      <dxi-group-item
        name="AgregatValoareStornata"
        summaryType="custom"
        valueFormat="#0.00 RON"
        column="ValoareStornata"
        [alignByColumn]="true">
      </dxi-group-item>
      <dxi-group-item
        name="AgregatCantitateTotala"
        summaryType="custom"
        valueFormat="#0 buc"
        column="CantitateTotala"
        [alignByColumn]="true">
      </dxi-group-item>
      <dxi-group-item
        name="AgregatCantitateVanduta"
        summaryType="custom"
        valueFormat="#0 buc"
        column="CantitateVanduta"
        [alignByColumn]="true">
      </dxi-group-item>
      <dxi-group-item
        name="AgregatCantitateStornata"
        summaryType="custom"
        valueFormat="#0 buc"
        column="CantitateStornata"
        [alignByColumn]="true">
      </dxi-group-item>
      <dxi-group-item
        name="AgregatMarjaTotala"
        summaryType="custom"
        valueFormat="#0.00 RON"
        column="MarjaTotala"
        [alignByColumn]="true">
      </dxi-group-item>
      <dxi-group-item
        name="AgregatStocRamas"
        summaryType="custom"
        valueFormat="Stoc ramas: #0 buc"
        column="StocRamas">
      </dxi-group-item>
      <dxi-group-item
        name="AgregatValoareUnitaraVanzare"
        summaryType="custom"
        valueFormat="#0.00 RON"
        showInColumn="ValoareUnitaraVanzare"
        [alignByColumn]="true">
      </dxi-group-item>
      <dxi-group-item
        name="AgregatValoareUnitaraAchizitionare"
        summaryType="custom"
        valueFormat="#0.00 RON"
        showInColumn="ValoareUnitaraAchizitionare"
        [alignByColumn]="true">
      </dxi-group-item>
      <dxi-group-item
        name="AgregatMarjaProcentuala"
        summaryType="custom"
        valueFormat="percent"
        showInColumn="MarjaProcentuala"
        [alignByColumn]="true">
      </dxi-group-item>
      <dxi-group-item
        name="AgregatMarjaUnitara"
        summaryType="custom"
        valueFormat="#0.00 RON"
        showInColumn="MarjaUnitara"
        [alignByColumn]="true">
      </dxi-group-item>


      <dxi-total-item
        name="AgregatValoareTotala"
        summaryType="custom"
        valueFormat=",##0.00 RON"
        column="ValoareTotala"
      >
      </dxi-total-item>
      <dxi-total-item
        name="AgregatValoareVanduta"
        summaryType="custom"
        valueFormat=",##0.00 RON"
        column="ValoareVanduta"
      >
      </dxi-total-item>
      <dxi-total-item
        name="AgregatValoareStornata"
        summaryType="custom"
        valueFormat=",##0.00 RON"
        column="ValoareStornata"
      >
      </dxi-total-item>
      <dxi-total-item
        name="AgregatCantitateTotala"
        summaryType="custom"
        valueFormat=",##0 buc"
        column="CantitateTotala"
      >
      </dxi-total-item>
      <dxi-total-item
        name="AgregatCantitateVanduta"
        summaryType="custom"
        valueFormat=",##0 buc"
        column="CantitateVanduta"
      >
      </dxi-total-item>
      <dxi-total-item
        name="AgregatCantitateStornata"
        summaryType="custom"
        valueFormat=",##0 buc"
        column="CantitateStornata"
      >
      </dxi-total-item>
      <dxi-total-item
        name="AgregatMarjaTotala"
        summaryType="custom"
        valueFormat=",##0.00 RON"
        column="MarjaTotala"
      >
      </dxi-total-item>
      <dxi-total-item
        name="AgregatStocRamas"
        summaryType="custom"
        valueFormat="Stoc ramas: ,##0 buc"
        column="StocRamas">
      </dxi-total-item>
      <dxi-total-item
        name="AgregatValoareUnitaraVanzare"
        summaryType="custom"
        valueFormat=",##0.00 RON"
        showInColumn="ValoareUnitaraVanzare"
      >
      </dxi-total-item>
      <dxi-total-item
        name="AgregatValoareUnitaraAchizitionare"
        summaryType="custom"
        valueFormat=",##0.00 RON"
        showInColumn="ValoareUnitaraAchizitionare"
      >
      </dxi-total-item>
      <dxi-total-item
        name="AgregatMarjaProcentuala"
        summaryType="custom"
        valueFormat="percent"
        showInColumn="MarjaProcentuala"
      >
      </dxi-total-item>
      <dxi-total-item
        name="AgregatMarjaUnitara"
        summaryType="custom"
        valueFormat=",##0.00 RON"
        showInColumn="MarjaUnitara"
      >
      </dxi-total-item>

      <dxi-total-item
        name="EtichetaTotal"
        summaryType="custom"
        showInColumn="Ziua"
      >
      </dxi-total-item>

    </dxo-summary>


  </dx-data-grid>
</div>

