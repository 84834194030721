<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand">Dashboard Comenzi</a>
  <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"
          data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
    <span class="navbar-toggler-icon"></span>
  </button>


  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" style="padding: 15px">
        <div class="form-check">
          <input (click)="setVertical()" checked class="form-check-input" id="vertical" name="verticalSwitch"
                 type="radio" value="vertical">
          <label class="form-check-label" for="vertical">
            Aranjare vericală
          </label>
        </div>
        <div class="form-check">
          <input (click)="setHorizontal()" class="form-check-input" id="horizontal" name="verticalSwitch" type="radio"
                 value="option2">
          <label class="form-check-label" for="horizontal">
            Aranjare orizontală
          </label>
        </div>
      </li>
      <li style="padding: 15px">
        <div class="form-check">
          <input [(ngModel)]="clienti" checked class="form-check-input" id="clienti" type="checkbox" value="">
          <label class="form-check-label" for="clienti">
            Comenzi Picking
          </label>
        </div>
        <div class="form-check">
          <input [(ngModel)]="furnizori" checked class="form-check-input" id="furnizori" type="checkbox" value="">
          <label class="form-check-label" for="furnizori">
            Comenzi Aprovizionare
          </label>
        </div>
      </li>


    </ul>
    <button (click)="switchRaportVanzari()" class="btn btn-info" style="margin: 5px">Raport Vânzări</button>
    <button (click)="logout()" class="btn btn-danger" style="margin: 5px">Logout</button>

  </div>
</nav>

<div *ngIf="isVertical">
  <div *ngIf="clienti">
    <br>
    <div class="container-fluid">
      <h2>Comenzi Picking</h2>
      <h4>{{comenzi_clienti.length == 1 ? "o comandă în lucru" : (comenzi_clienti.length + " comenzi în lucru")}}</h4>
      <h4>{{getNewComenziClienti().length == 1 ? "o comandă în lucru în ultimele 10 minute" : (getNewComenziClienti().length + " comenzi în lucru în ultimele 10 minute")}}</h4>
    </div>

    <br>
    <div class="container-fluid">
      <table class="table table-sm">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Cod</th>
          <th scope="col">Client</th>
          <!--          <th scope="col">Dată</th>-->
          <!--          <th scope="col"></th>-->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let cc of comenzi_clienti" [ngClass]="{'new-entry' : isNew(cc.ComandaDataCreare) }">
          <th scope="row" style="font-size: 25px;">{{cc.NrCrt}}</th>
          <td style="font-size: 25px;">{{cc.CodComanda}}</td>
          <td style="font-size: 25px;">{{cc.Client}}</td>
          <!--          <td style="font-size: 25px;">{{cc.ComandaDataCreare.substr(0, 19).replace('T', ' ')}}</td>-->
          <!--          <td *ngIf="isNew(cc.ComandaDataCreare)">COMANDĂ NOUĂ</td>-->
          <!--          <td *ngIf="!isNew(cc.ComandaDataCreare)"></td>-->
        </tr>

        </tbody>
      </table>
    </div>
  </div>


  <div *ngIf="furnizori">
    <br>
    <div class="container-fluid">
      <h2>Comenzi Aprovizionare</h2>
      <h4>{{comenzi_aprovizionare.length == 1 ? "o comandă în lucru" : (comenzi_aprovizionare.length + " comenzi în lucru")}}</h4>
      <h4>{{getNewComenziAprovizionare().length == 1 ? "o comandă în lucru în ultimele 10 minute" : (getNewComenziAprovizionare().length + " comenzi în lucru în ultimele 10 minute")}}</h4>
    </div>
    <br>
    <div class="container-fluid">
      <table class="table table-sm">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Cod</th>
          <th scope="col">Furnizor</th>
          <!--          <th scope="col">Dată</th>-->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let ca of comenzi_aprovizionare" [ngClass]="{'new-entry' : isNew(ca.ComandaDataCreare) }">
          <th scope="row" style="font-size: 25px;">{{ca.NrCrt}}</th>
          <td style="font-size: 25px;">{{ca.CodComanda}}</td>
          <td style="font-size: 25px;">{{ca.Furnizor}}</td>
          <!--          <td style="font-size: 25px;">{{ca.ComandaDataCreare.substr(0, 19).replace('T', ' ')}}</td>-->
          <!--          <td *ngIf="isNew(ca.ComandaDataCreare)">COMANDĂ NOUĂ</td>-->
          <!--          <td *ngIf="!isNew(ca.ComandaDataCreare)"></td>-->
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>


<div *ngIf="!isVertical" class="row">
  <div *ngIf="clienti" class="table-responsive col-md-6">
    <br>
    <div class="container-fluid">
      <h2>Comenzi Picking</h2>
      <h4>{{comenzi_clienti.length == 1 ? "o comandă în lucru" : (comenzi_clienti.length + " comenzi în lucru")}}</h4>
      <h4>{{getNewComenziClienti().length == 1 ? "o comandă în lucru în ultimele 10 minute" : (getNewComenziClienti().length + " comenzi în lucru în ultimele 10 minute")}}</h4>
    </div>

    <br>
    <div class="container-fluid">
      <table class="table table-sm">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Cod</th>
          <th scope="col">Client</th>
          <!--          <th scope="col">Dată</th>-->
          <!--          <th scope="col"></th>-->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let cc of comenzi_clienti" [ngClass]="{'new-entry' : isNew(cc.ComandaDataCreare) }">
          <th scope="row" style="font-size: 25px;">{{cc.NrCrt}}</th>
          <td style="font-size: 25px;">{{cc.CodComanda}}</td>
          <td style="font-size: 25px;">{{cc.Client}}</td>
          <!--          <td style="font-size: 25px;">{{cc.ComandaDataCreare.substr(0, 19).replace('T', ' ')}}</td>-->
          <!--          <td *ngIf="isNew(cc.ComandaDataCreare)">COMANDĂ NOUĂ</td>-->
          <!--          <td *ngIf="!isNew(cc.ComandaDataCreare)"></td>-->
        </tr>

        </tbody>
      </table>
    </div>

  </div>
  <div *ngIf="furnizori" class="table-responsive col-md-6">
    <br>
    <div class="container-fluid">
      <h2>Comenzi Aprovizionare</h2>
      <h4>{{comenzi_aprovizionare.length == 1 ? "o comandă în lucru" : (comenzi_aprovizionare.length + " comenzi în lucru")}}</h4>
      <h4>{{getNewComenziAprovizionare().length == 1 ? "o comandă în lucru în ultimele 10 minute" : (getNewComenziAprovizionare().length + " comenzi în lucru în ultimele 10 minute")}}</h4>
    </div>
    <br>
    <div class="container-fluid">
      <table class="table table-sm">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Cod</th>
          <th scope="col">Furnizor</th>
          <!--          <th scope="col">Dată</th>-->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let ca of comenzi_aprovizionare" [ngClass]="{'new-entry' : isNew(ca.ComandaDataCreare) }">
          <th scope="row" style="font-size: 25px;">{{ca.NrCrt}}</th>
          <td style="font-size: 25px;">{{ca.CodComanda}}</td>
          <td style="font-size: 25px;">{{ca.Furnizor}}</td>
          <!--          <td style="font-size: 25px;">{{ca.ComandaDataCreare.substr(0, 19).replace('T', ' ')}}</td>-->
          <!--          <td *ngIf="isNew(ca.ComandaDataCreare)">COMANDĂ NOUĂ</td>-->
          <!--          <td *ngIf="!isNew(ca.ComandaDataCreare)"></td>-->
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
