import {Component, OnInit} from '@angular/core';
import {SharedResources} from "../../services/shared_resources/shared-resources";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {finalize} from "rxjs/operators";


@Component({
  selector: 'app-raport-vanzari',
  templateUrl: './raport-vanzari.component.html',
  styleUrls: ['./raport-vanzari.component.css']
})
export class RaportVanzariComponent implements OnInit {


  constructor(private http: HttpClient) {
  }

  dataSource: any = [];

  monthData: any = [];

  anualData: any = [];

  productData: any = [];

  channelData: any = [];

  loadIndicatorVisible: any = false;
  startDate: any;
  endDate: any;


  ngOnInit(): void {
    this.loadIndicatorVisible = true;
    this.endDate = new Date().toISOString().slice(0, 10);
    this.startDate = new Date(Date.now() - 1000 * 60 * 60 * 24 * 31 * 3).toISOString().slice(0, 10);
    this.http.get<any>(`${localStorage.getItem('APIAddress')}Reports/RaportVanzariV2Zile?startDate=${this.startDate}&endDate=${this.endDate}`,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        })
      }).subscribe(
        response => {
            this.dataSource = response;
            this.loadIndicatorVisible = false;
            //console.log(response);
        }, error => {
          alert("Eroare la încărcarea datelor!");
          this.loadIndicatorVisible = false;
        });
    // this.http.get<any>(`${localStorage.getItem('APIAddress')}Reports/RaportVanzariV2Luni?startDate=2022-12-20&endDate=2023-05-24`,
    //   {
    //     headers: new HttpHeaders({
    //       "Content-Type": "application/json",
    //       "Authorization": `Bearer ${localStorage.getItem('token')}`
    //     })
    //   }).subscribe(
    //   response => {
    //     this.monthData = response;
    //   }, error => {
    //     alert("Eroare la incarcarea datelor!");
    //   });
    // this.http.get<any>(`${localStorage.getItem('APIAddress')}Reports/RaportVanzariV2Ani?startDate=2022-12-20&endDate=2023-05-24`,
    //   {
    //     headers: new HttpHeaders({
    //       "Content-Type": "application/json",
    //       "Authorization": `Bearer ${localStorage.getItem('token')}`
    //     })
    //   }).subscribe(
    //   response => {
    //     this.anualData = response;
    //   }, error => {
    //     alert("Eroare la incarcarea datelor!");
    //   });
    // this.http.get<any>(`${localStorage.getItem('APIAddress')}Reports/RaportVanzariV2CanalVanzare?startDate=2022-12-20&endDate=2023-05-24`,
    //   {
    //     headers: new HttpHeaders({
    //       "Content-Type": "application/json",
    //       "Authorization": `Bearer ${localStorage.getItem('token')}`
    //     })
    //   }).subscribe(
    //   response => {
    //     this.channelData = response;
    //   }, error => {
    //     alert("Eroare la incarcarea datelor!");
    //   });
    //
    // this.http.get<any>(`${localStorage.getItem('APIAddress')}Reports/RaportVanzariV2Produse?startDate=2022-12-20&endDate=2023-05-24`,
    //   {
    //     headers: new HttpHeaders({
    //       "Content-Type": "application/json",
    //       "Authorization": `Bearer ${localStorage.getItem('token')}`
    //     })
    //   }).subscribe(
    //   response => {
    //     this.productData = response;
    //   }, error => {
    //     alert("Eroare la incarcarea datelor!");
    //   });
  }

  convertNumberToMonthName(monthNumber: number): string {
    const monthNames = [
      "ianuarie", "februarie", "martie", "aprilie", "mai", "iunie",
      "iulie", "august", "septembrie", "octombrie", "noiembrie", "decembrie"
    ];
    return monthNames[monthNumber - 1];
  }

  logout() {
    localStorage.setItem('token', '');
    localStorage.setItem('isAuth', 'false');
    SharedResources._tokenBS.next(localStorage.getItem('token'));
    SharedResources._isAuthBS.next(false);
    SharedResources.currentScreen = 'login';
    SharedResources._currentScreenBS.next('login');
  }

  switchComenzi() {
    SharedResources.currentScreen = 'display';
    SharedResources._currentScreenBS.next('display');
  }

  calculeazaAgregate(options: any) {
    if (['AgregatValoareStornata', 'AgregatValoareVanduta', 'AgregatValoareTotala',
        'AgregatCantitateStornata', 'AgregatCantitateVanduta', 'AgregatCantitateTotala',
        'AgregatMarjaTotala'].includes(options.name)) {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        options.totalValue += options.value;

      }
      return;
    }


    if (options.name === 'AgregatStocRamas' && options.groupIndex == 0) {
      if (options.totalValue === undefined && options.summaryProcess === 'calculate') {
        options.totalValue = options.value;
      }
      return;
    }


    if (options.name === 'AgregatValoareUnitaraVanzare') {

      if (options.summaryProcess === 'start') {
        options.cantitateTotala = 0;
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        options.totalValue += options.value.CantitateTotala * options.value.ValoareUnitaraVanzare;
        options.cantitateTotala +=  options.value.CantitateTotala;
      } else if (options.summaryProcess === 'finalize') {
        options.totalValue /= options.cantitateTotala;
      }

    }



    if (options.name === 'AgregatValoareUnitaraAchizitionare') {

      if (options.summaryProcess === 'start') {
        options.cantitateTotala = 0;
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        options.totalValue += options.value.CantitateTotala * options.value.ValoareUnitaraAchizitionare;
        options.cantitateTotala +=  options.value.CantitateTotala;
      } else if (options.summaryProcess === 'finalize') {
        options.totalValue /= options.cantitateTotala;
      }

    }



    if (options.name === 'AgregatMarjaProcentuala') {

      if (options.summaryProcess === 'start') {
        options.valoareVanzare = 0;
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        options.totalValue += options.value.CantitateTotala * options.value.ValoareUnitaraAchizitionare;
        options.valoareVanzare +=  options.value.CantitateTotala * options.value.ValoareUnitaraVanzare;
      } else if (options.summaryProcess === 'finalize') {
        options.totalValue = (options.valoareVanzare - options.totalValue) / options.valoareVanzare;
      }

    }


    if (options.name === 'AgregatMarjaUnitara') {

      if (options.summaryProcess === 'start') {
        options.valoareVanzare = 0;
        options.cantitateTotala = 0;
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        options.totalValue += options.value.CantitateTotala * options.value.ValoareUnitaraAchizitionare;
        options.valoareVanzare +=  options.value.CantitateTotala * options.value.ValoareUnitaraVanzare;
        options.cantitateTotala +=  options.value.CantitateTotala;
      } else if (options.summaryProcess === 'finalize') {
        options.totalValue = (options.valoareVanzare - options.totalValue) / options.cantitateTotala;
      }

    }

    if (options.name === 'EtichetaTotal') {
      if (options.summaryProcess === 'finalize') {
        options.totalValue = "TOTAL";
      }
    }


  }

  applyDateFilters() {
    this.http.get<any>(`${localStorage.getItem('APIAddress')}Reports/RaportVanzariV2Zile?startDate=${this.startDate}&endDate=${this.endDate}`,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        })
      }).subscribe(
      response => {
        this.dataSource = response;
        this.loadIndicatorVisible = false;
        //console.log(response);
      }, error => {
        alert("Eroare la încărcarea datelor!");
        this.loadIndicatorVisible = false;
      });
  }
}
