import { Component, OnInit } from '@angular/core';
import {NgForm} from "@angular/forms";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SharedResources} from "../../services/shared_resources/shared-resources";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  public login (form: NgForm) {
    localStorage.setItem('APIAddress', form.value.api);
    this.http.post(`${localStorage.getItem('APIAddress')}token`,
      `grant_type=password&username=${form.value.username}&password=${form.value.password}`,
      {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    }).subscribe(
      response => {
        localStorage.setItem('token', (<any>response).access_token);
        localStorage.setItem('isAuth', 'true');
        SharedResources._tokenBS.next(localStorage.getItem('token'));
        SharedResources._isAuthBS.next(true);
        SharedResources.currentScreen = 'display';
        SharedResources._currentScreenBS.next('display');
        localStorage.setItem('loginTime', String(Date.now()));
      }, error => {
        alert("Nume de utilizator sau parolă incorecte!");
        localStorage.setItem('token', '');
        localStorage.setItem('isAuth', 'false');
        SharedResources._tokenBS.next('');
        SharedResources._isAuthBS.next(false);
      }
    );
  }
}
