import { Component } from '@angular/core';
import {SharedResources} from "../services/shared_resources/shared-resources";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Dashboard Comenzi';
  currentScreen: string = '';
  constructor() {}
  ngOnInit() {
    SharedResources._currentScreenBS.subscribe((data: any) => {
      this.currentScreen = data;
    });
    if (localStorage.getItem('isAuth') == null) {
      localStorage.setItem('isAuth', 'false');
      localStorage.setItem('token', '');
      this.currentScreen = 'login';
    } else {
      this.currentScreen = localStorage.getItem('isAuth') == 'true' ? 'display' : 'login';
    }
  }
}
