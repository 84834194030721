import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { DisplayComponent } from './display/display.component';
import { LoginComponent } from './login/login.component';
import {FormsModule} from "@angular/forms";
import { RaportVanzariComponent } from './raport-vanzari/raport-vanzari.component';
import {
  DxBulletModule,
  DxButtonModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxLoadIndicatorModule
} from "devextreme-angular";

@NgModule({
  declarations: [
    AppComponent,
    DisplayComponent,
    LoginComponent,
    RaportVanzariComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    DxDataGridModule,
    DxBulletModule,
    DxLoadIndicatorModule,
    DxDateBoxModule,
    DxButtonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
