import {BehaviorSubject} from "rxjs";

export class SharedResources {
  public static currentScreen: string = 'login'
  public static _currentScreenBS: any = new BehaviorSubject<any>('')
  public static APIAddress: string = '';
  public static _tokenBS: any = new BehaviorSubject<any>('');
  public static _isAuthBS: any = new BehaviorSubject<any>('');

}
